import { Component } from '@angular/core';
import { Router,  RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from "src/app/shared/services/authentication.service";

@Component({
  selector: 'ngbd-modal-content',
  template: `
  <div class="modal-header">
            <h4 class="modal-title">Help / Support</h4>
            <p></p>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
    <div class="row">
   
   You can contact with our support team at support@transform.com for more assistance.
      </div>
    </div>
  `,
  styleUrls: ["./help-support-modal.component.scss"],
})
export class NgbdModalContent {
  snapshot: RouterStateSnapshot;
  constructor(public activeModal: NgbActiveModal
) {}


}

@Component({
  selector: 'help-support-modal',
  templateUrl: './help-support-modal.component.html'
})
export class HelpSupportModal {
  constructor(private modalService: NgbModal,
    ) {}

  open() {
    const modalRef = this.modalService.open(NgbdModalContent, {size: 'xl', windowClass: 'custom-class', centered: true});
  }
}








