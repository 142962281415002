export class Constants {
  public static DocumentReferrer = "SourceReferrer";
  public static MinDateValue = -8640000000000000;
  public static MPNID_DEFAULT = "1234";
  public static YammerFeedLimit = 5;
  static DefaultModuleImages: any;
}

export enum SystemPermissionConstant {
  TechReadinessAdmin = "techReadiness_admin",
}

export enum FilterRuleEnum {
  AND = "AND",
  OR = "OR",
}

export enum IconClassMapperEnum {
  Document = "ms-word-icon",
  Video = "ms-VideoIcon",
  PPT = "ms-ppt-icon",
}

export const AssetFileExtensions = [
  "doc",
  "docx",
  "xls",
  "xlsx",
  "ppt",
  "pptx",
  "mp4",
  "m4v"
];

export enum ProfileImageDimenssionEnum {
  Dimenssion48 = "48x48",
  Dimenssion96 = "96x96",
}

export const DefaultModuleImages = [
	"images/default-module/Image1.png",
	"images/default-module/Image2.png",
	"images/default-module/Image3.png",
	"images/default-module/Image4.png",
	"images/default-module/Image5.png",
	"images/default-module/Image6.png",
	"images/default-module/Image7.png",
	"images/default-module/Image8.png",
	"images/default-module/Image9.png",
	"images/default-module/Image10.png",
	"images/default-module/Image11.png",
	"images/default-module/Image12.png",
	"images/default-module/Image13.png",
	"images/default-module/Image14.png",
	"images/default-module/Image15.png",
];

export enum siteRole
{
  Partner="Partner",
  Microsoft= "Microsoft",
  Other="Other",
  Anonymous ="Anonymous"
}

export enum ContainerEnum {
  Assets = "assets",
  Images = "images",
}

export enum RedirectLocation {
  Current = "current",
  Dashboard = "dashboard",
  Home = "home"
}