import { Injectable } from "@angular/core";
import { AdalService } from "adal-angular4";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "./user.service";
import * as _ from "lodash";
import { Location } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class AppNavigationService {
  public static DownloadUrlKey = "downloadUrl";
  constructor(
    private userService: UserService,
    private adalService: AdalService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) { }

  public configureAppRouting(): void {
    // if (this.userService.isTransformEnabled) {
    //   this.router.resetConfig(appRoutesTransform);
    // }
    this.tryRestoreDownloadLink(); //#RELATED 154381 BUG Additional handling for restoring download route incase if app token navigates to Login and redirects back
    this.deeplinkOrNavigateHome();
    // if (!hasNavigated && this.userService.isTransformEnabled) {
    //   const currentPath = this.location.path();
    //   this.router.navigateByUrl(currentPath != '' ? currentPath : '/');
    // }
  }

  //#RELATED 154381 BUG Additional handling for saving route incase if app token navigates to Login
  public trySaveDownloadLink(): void {
    const checkUrlContains = (value: string): boolean =>
      _.includes(decodeURIComponent(this.location.path()), value);
    if (
      checkUrlContains("/download?") &&
      !checkUrlContains("login") &&
      !checkUrlContains("returnUrl")
    ) {
      window.sessionStorage.setItem(
        AppNavigationService.DownloadUrlKey,
        window.location.pathname + window.location.search
      );
    }
  }

  //#RELATED 154381 BUG Additional handling for restoring download route incase if app token navigates to Login and redirects back
  public tryRestoreDownloadLink(): void {
    if (
      window.sessionStorage &&
      window.sessionStorage.getItem(AppNavigationService.DownloadUrlKey)
    ) {
      if (
        window.sessionStorage.getItem("returnUrl") ||
        _.includes(decodeURIComponent(window.location.href), "/download?")
      ) {
        window.sessionStorage.removeItem(AppNavigationService.DownloadUrlKey);
      } else if (!window.sessionStorage.getItem("returnUrl")) {
        const downloadUrl = window.sessionStorage.getItem(
          AppNavigationService.DownloadUrlKey
        );
        window.sessionStorage.removeItem(AppNavigationService.DownloadUrlKey);
        window.sessionStorage.setItem("returnUrl", downloadUrl);
      }
    }
  }

  public deeplinkOrNavigateHome(): boolean {
    let hasNavigated = false;
    const path = this.location.path();
    let redirectUrl;
    if (window.sessionStorage && window.sessionStorage.getItem("returnUrl")) {
      redirectUrl = window.sessionStorage.getItem("returnUrl");
      window.sessionStorage.removeItem("returnUrl");
    }
    if (this.userService.firstRunExperiance) {
      if (
        _.isNil(redirectUrl) &&
        this.userService.homeRoute !== window.location.pathname
      ) {
        redirectUrl = window.location.pathname;
      }
      _.isNil(redirectUrl)
        ? this.router.navigate(["/firstrun"])
        : this.router.navigate(["/firstrun"], {
          queryParams: { returnUrl: redirectUrl },
        });
      hasNavigated = true;
    } else if (!_.isNil(redirectUrl)) {
      if (_.includes(redirectUrl, "?tab") && this.userService.isPartner) {
        this.router.navigate(["/error"], {
          queryParams: { error: "403" },
          skipLocationChange: true,
        });
        hasNavigated = true;
      } else {
        this.router.navigateByUrl(redirectUrl);
        hasNavigated = true;
      }
    } else if (_.includes(path, "login")) {
      this.router.navigate(["/techReadinessHub"]);
      hasNavigated = true;
    }
    return hasNavigated;
  }

  public saveReturnUrlToSession(): void {
    if (!_.isNil(this.route.snapshot.queryParams["returnUrl"])) {
      const redirectUrl = decodeURIComponent(
        this.route.snapshot.queryParams["returnUrl"]
      );
      if (
        window.sessionStorage &&
        (_.includes(redirectUrl, "?") || _.includes(redirectUrl, "#"))
      ) {
        //Hack for url with query string - adal is not properly redirecting back for url with parameters
        window.sessionStorage.setItem("returnUrl", redirectUrl);
        this.adalService.config.redirectUri = window.location.origin;
      } else {
        this.adalService.config.redirectUri =
          window.location.origin + redirectUrl;
      }
    }
    else {
      this.adalService.config.redirectUri = window.location.href;
    }
  }
}
