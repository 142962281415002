/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Injectable } from "@angular/core";
import { AppConfig } from "src/app/app.config";
import { AppInsights } from "applicationinsights-js";
import { UserService } from "../../shared/services/user.service";
import * as _ from "lodash";
import { Constants } from "../constants";

@Injectable({
  providedIn: "root",
})
export class AppInsightsService {
  private config: Microsoft.ApplicationInsights.IConfig = {
    instrumentationKey: AppConfig.instrumentationKey,
  };
  constructor(private userService: UserService) {
    if (!AppInsights.config) {
      AppInsights.downloadAndSetup(this.config);
    }
  }
  /**
   * Logs that a page or other item was viewed.
   * @param   name  The string you used as the name in `startTrackPage`. Defaults to the document title.
   * @param   url   String - a relative or absolute URL that identifies the page or other item.
   *  Defaults to the window location.
   * @param   properties  map[string, string] - additional data used to filter pages and metrics in the portal.
   *  Defaults to empty.
   * @param   measurements    map[string, number] - metrics associated with this page,
   * displayed in Metrics Explorer on the portal. Defaults to empty.
   * @param   duration    number - the number of milliseconds it took to load the page.
   * Defaults to undefined. If set to default value, page load time is calculated internally.
   */

  public async trackPageView(
    name?: string,
    url?: string,
    properties?: any,
    measurements?: any,
    duration?: number
  ) {
    const userInfo = await this.userService.userInformation;
    name = _.isEmpty(name) ? "home" : name;
    let generalProperties = {};
    const domainName = userInfo?.email?.split("@")[1];
    const tags = AppConfig.enviorment;
    generalProperties = {
      id: userInfo?.id,
      domainName: domainName,
      segment: userInfo?.businessSegmentId,
      role: userInfo?.roleId,
      tags: tags,
      sourceReferrer: sessionStorage.getItem(Constants.DocumentReferrer),
      windowUrl: window.location.href,
    };

    properties = _.assign(properties, _.merge(properties, generalProperties));
    AppInsights.trackPageView(name, url, properties, measurements, duration);
  }
  /**
   * Log a user action or other occurrence.
   * @param   name    A string to identify this event in the portal.
   * @param   properties  map[string, string] - additional data used to filter events and metrics in the portal.
   * Defaults to empty.
   * @param   measurements    map[string, number] - metrics associated with this event, displayed in Metrics Explorer on the portal.
   * Defaults to empty.
   */
  public async trackEvent(name: string, properties?: any, measurements?: any) {
    const userInfo = await this.userService.userInformation;
    name = _.isEmpty(name) ? "home" : name;
    let generalProperties = {};
    const tags = AppConfig.enviorment;
    const domainName = userInfo?.email?.split("@")[1];

    generalProperties = {
      id: userInfo?.id,
      domainName: domainName,
      segment: userInfo?.businessSegmentId,
      role: userInfo?.roleId,
      tags: tags,
      sourceReferrer: sessionStorage.getItem(Constants.DocumentReferrer),
      windowUrl: window.location.href,
    };

    properties = _.assign(properties, _.merge(properties, generalProperties));
    AppInsights.trackEvent(name, properties, measurements);
  }
  /**
   * Log an exception you have caught.
   * @param   exception   An Error from a catch clause, or the string error message.
   * @param   properties  map[string, string] - additional data used to filter events and metrics in the portal. Defaults to empty.
   * @param   measurements    map[string, number] - metrics associated with this event, displayed in Metrics Explorer on the portal.
   *  Defaults to empty.
   * @param   severityLevel   AI.SeverityLevel - severity level
   */
  trackException(
    exception: Error,
    handledAt?: string,
    properties?: { [name: string]: string },
    measurements?: { [name: string]: number },
    severityLevel?: AI.SeverityLevel
  ) {
    AppInsights.trackException(
      exception,
      handledAt,
      properties,
      measurements,
      severityLevel
    );
  }
}
