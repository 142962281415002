
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'matchesRole'
})

export class MatchesRolePipe implements PipeTransform {
    private filterLevelGuid = "72c19554-fcde-4c9d-968c-b78db2dcb017";
    private filterRoleGuid = "b96cffe5-35de-4dcc-b482-5bb6b12035f0";
    //ToDo: filterTypeGuid for resources type
  
    transform(items: Array<any>, filterType: string): Array<any> {
        let filterId ="";
        switch (filterType.toLowerCase()){
            case "level": filterId = this.filterLevelGuid;
            break;
            case "role": filterId = this.filterRoleGuid;
            break;
        }

        return items.filter(item => item.filterTypeId === filterId);
    }
}