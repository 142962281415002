import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-list-pagination",
  templateUrl: "./list-pagination.component.html",
  styleUrls: ["./list-pagination.component.scss"],
})
export class ListPaginationComponent implements OnInit {
  @Input() totalPages: number;
  @Input() pageSize: number;
  @Output() currentPage = new EventEmitter();
  public page = 1;
  public index: number;

  ngOnInit(): void {
    this.currentPage.emit(this.page);
  }

  public get collectionSize(): number {
    return this.totalPages * this.pageSize;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public pageChanged(page): void {
    window.scroll(0, 0);
    this.currentPage.emit(page);
  }
}
