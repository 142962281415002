export interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
  {
    name: "uhfMainScript",
    src:
      "https://statics-uhf-eus.akamaized.net/shell/_scrf/js/themes=default/54-af9f9f/c0-247156/" +
      "de-099401/e1-a50eee/e7-954872/d8-97d509/f0-251fe2/46-be1318/77-04a268/7f-652c90/63-077520/" +
      "a4-34de62/75-71ddfc/db-bc0148/dc-7e9864/78-4c7d22/9f-d154ca/e4-8302f6/cd-23d3b0/6d-1e7ed0/" +
      "b7-cadaa7/ca-40b7b0/4e-ee3a55/3e-f5c39b/c3-6454d7/f9-7592d3/10-0288cd/79-499886/7e-cda2d3/" +
      "32-6dafa3/93-283c2d/e0-3c9860/91-97a04f/1f-100dea/33-abe4df/18-d72213?ver=2.0&iife=1",
  },
];
