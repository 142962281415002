/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Component,
  OnInit,
  OnChanges,
  Renderer2,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  SimpleChange,
} from "@angular/core";
import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
import { AppInsightsService } from "src/app/shared/services/app.insights.service";
import { UserService } from "src/app/shared/services/user.service";
import { TechReadinessHubApi } from "src/app/api/techReadinessHubapi";

@Component({
  selector: "app-searchv2",
  templateUrl: "./searchv2.component.html",
  styleUrls: ["./searchv2.component.scss"],
})
export class SearchV2Component implements OnInit {
  @Output() searchItem = new EventEmitter();
  @Output() searchKeyOnChange = new EventEmitter();
  public showClearSearch: boolean;
  public searchKeyword: any;
  @Input() searchKey: string;
  @Input() redirectPath: string;
  @Input() currentTab: string;
  public suggestions: any;
  public hasSuggestions = false;
  isSearchClicked: boolean;
  subject: Subject<any> = new Subject();
  @ViewChild("mainSearch", { static: true }) searchInputText: ElementRef;
  constructor(
    private renderer: Renderer2,
    private router: Router,
    private appInsightsService: AppInsightsService,
    private techReadinessHubApiService: TechReadinessHubApi,
    private userService: UserService  ) {}

  ngOnInit(): void {
    if (this.searchInputText !== undefined) {
      this.renderer.removeAttribute(
        this.searchInputText.nativeElement,
        "aria-multiline"
      );
    }
    this.subject.pipe(debounceTime(1000)).subscribe(async () => {
      if (this.searchKey && this.searchKey.length > 0) {
        const data: any = await this.techReadinessHubApiService.searchSuggestions(
          this.searchKey
        );
        if (!this.isSearchClicked) {
          this.suggestions = data.suggestions;
          this.hasSuggestions = true;
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChange) {
    if (changes["searchKey"]) {
      this.redirectToSearch();
    }
  }

  public search = (text$: Observable<string>): any =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => {
        if (term.length >= 2) {
          return [];
        } else {
          return [];
        }
      })
    );

  redirect(event: any): void {
    this.hasSuggestions = false;
    if (event.item || this.searchKey) {
      this.searchKeyword = {
        "Search Keyword used": this.searchKey,
      };
      this.appInsightsService.trackEvent("search", this.searchKeyword);
      if (!event.item) {
        event.item = this.searchKey;
      }
    }
    this.redirectToSearch();
  }

  onFocus(e: Event): void {
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event("input");
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  clearSearch(): void {
    this.showClearSearch = false;
    this.searchKey = null;
    this.clearSuggestions();
  }

  public async autoComplete(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      return;
    }
    this.isSearchClicked = false;
    if (
      this.searchKey &&
      this.searchKey.length > 0 &&
      this.currentTab == "Document"
    ) {
      this.subject.next(this.searchKey);
    } else {
      this.clearSuggestions();
    }
  }

  public setAndSearch(item: any) {
    this.searchKey = '"' + item.text + '"';
    this.hasSuggestions = false;
    this.redirectToSearch();
    this.searchKeyword = {
      "Search Keyword used": this.searchKey,
    };
    this.appInsightsService.trackEvent("search", this.searchKeyword);
    this.clearSuggestions();
  }

  public redirectToSearch() {
    this.isSearchClicked = true;
    this.hasSuggestions = false;
    const redirectPath = this.redirectPath ? this.redirectPath : "/search";
    if (
      this.router.url == "/" ||
      this.router.url != `${redirectPath}/${this.searchKey}`
    ) {
      this.router.navigate([redirectPath, this.searchKey]);
    }

    this.searchItem.emit(this.searchKey);
  }

  public onSearckKeyChanged() {
    console.log(this.searchKey);
    this.router.navigate(["/search", this.searchKey]);
    this.searchKeyOnChange.emit(this.searchKey);
  }

  clearSuggestions() {
    this.hasSuggestions = false;
    this.suggestions = [];
  }

  displaySuggestion(data: any) {
    return JSON.stringify(data);
  }
}
