import { TechHubSearchFilterModel } from './../shared/models/techhub-search-filter-model';
import { ModuleModel } from './../shared/models/module.model';
import { TechReadinessAssetModel } from 'src/app/shared/models/techreadiness-asset.model';
import { KnowledgeCheckModel } from './../shared/models/knowledge-check.model';
import { addModulesRequestModel } from './../shared/models/add-modules-request.model';
import { Injectable } from '@angular/core';
import { techReadinessHubApiBase } from '../api/apiBase'
import { FilterListModel } from '../shared/models/filter-list';
import { SolutionAreasModel } from '../shared/models/solution-areas.model';
import { LearningPathModel } from '../shared/models/learning-list.model';
import { forkJoin, Observable } from 'rxjs';
import { EventModel } from '../shared/models/Events/event.model';
import { EventSessionModel } from '../shared/models/Events/event-session.model';
import { UserEventRegistrationModel } from '../shared/models/Events/user-event-registration.model';
import { AddUserEventRegistrationRequest } from '../shared/models/Events/add-user-event-registration-request.model';
import { UpdateUserEventRegistrationRequest } from '../shared/models/Events/update-user-event-registration-request.model';
import { AddUserEventSessionRequest } from '../shared/models/Events/add-user-event-session-request.model';
import { UpdateUserEventSessionRequest } from '../shared/models/Events/update-user-event-session-request.model';

@Injectable({
  providedIn: "root",
})
export class TechReadinessHubApi extends techReadinessHubApiBase {
  public getLearningpathList(): Promise<LearningPathModel> {
    return this.get<LearningPathModel>(`LearningPaths/list`);
  }
  public getUserLearningpathList(): Promise<LearningPathModel> {
    return this.get<LearningPathModel>(`UserLearningPath/list`);
  }
  public getUserFavoriteList() {
    return this.get(`Favorite/list`);
  }
  public getUserModulesList(learningPathId: string) {
    return this.get(`UserLearningPath/${learningPathId}/modules/list`);
  }
  public getUserLearningpath(learningPathId: string) {
    return this.get(`UserLearningPath/${learningPathId}`);
  }
  public deleteUserModule(moduleId: string) {
    return this.delete(`UserLearningPath/module/${moduleId}`);
  }

  public getLearningpathById(id: string): Promise<LearningPathModel> {
    return this.get<LearningPathModel>(`LearningPaths/${id}`);
  }

  public getLearningpathModulesById(id: string): Promise<ModuleModel[]> {
    return this.get<ModuleModel[]>(`LearningPaths/${id}/modules/list`);
  }
  public getAllFilters(): Promise<FilterListModel[]> {
    return this.get<FilterListModel[]>(`Filters/list`);
  }

  public getSolutionAreas(): Promise<SolutionAreasModel[]> {
    return this.get<SolutionAreasModel[]>(`SolutionAreas/list`);
  }

  public addModuleToPlan(id: string) {
    return this.post(`UserLearningPath/module?moduleId=${id}`, "");
  }

  public addFavouriteModule(moduleId: string): Promise<any> {
    return this.post(`Favorite/modules/${moduleId}`, "");
  }

  public addAllModulesToPlan(
    id: string,
    filterDetails: addModulesRequestModel
  ) {
    return this.post(`UserLearningPath/${id}/modules`, filterDetails);
  }

  public deleteFavouriteModule(moduleId: string) {
    return this.delete(`Favorite/modules/${moduleId}`);
  }
  public getModuleById(id: string, moduleid: string): Promise<ModuleModel> {
    return this.get(`LearningPaths/${id}/modules/${moduleid}`);
  }
  public getNextModuleById(id: string, moduleid: string) {
    return this.get(`UserLearningPath/${id}/module/${moduleid}/next`);
  }
  public updateModuleStatus(id: string, currentDuration: any) {
    return this.put(`UserLearningPath/module/${id}/status`, currentDuration);
  }
  public getModuleStatusById(moduleid: string) {
    return this.get(`UserLearningPath/Module/${moduleid}/Status`);
  }
  public getQuizQuestionsById(id: string): Promise<KnowledgeCheckModel> {
    return this.get<KnowledgeCheckModel>(`Quiz/module/${id}`);
  }

  public updateKnowledgeCheckStatus(id: string) {
    return this.put(`UserLearningPath/module/${id}/knowledgecheck`, "");
  }

  public getLearningpathWithFilterById(id: string): Observable<any> {
    return forkJoin([
      this.getLearningpathById(id),
      this.getLearningpathModulesById(id),
      this.getAllFilters()
    ]);
  }

  public getUserLearningpathWithFavModule(): Observable<any> {
    return forkJoin([this.getUserLearningpathList(), this.getUserFavoriteList()]);
  }

  public getUserLearningpathInfoWithModule(
    learningPathId: string
  ): Observable<any> {
    return forkJoin([
      this.getUserModulesList(learningPathId),
      this.getUserLearningpath(learningPathId)
    ]);
  }

  public getEventById(id: string): Promise<EventModel> {
    return this.get<EventModel>(`event/${id}`);
  }
  public getEvents(): Promise<EventModel[]> {
    return this.get<EventModel[]>(`event/list`);
  }
  public getEventSessions(eventId: string): Promise<EventSessionModel[]> {
    return this.get<EventSessionModel[]>(`event/${eventId}/session/list`);
  }
  public getEventSession(
    eventId: string,
    eventSessionId: string
  ): Promise<EventSessionModel> {
    return this.get<EventSessionModel>(
      `event/${eventId}/session/${eventSessionId}`
    );
  }
  public addUserEventSession(request: AddUserEventSessionRequest) {
    return this.post(
      `event/${request.eventId}/session/${request.sessionId}/user`,
      request
    );
  }
  public updateUserEventSession(request: UpdateUserEventSessionRequest) {
    return this.post(
      `event/${request.eventId}/session/${request.sessionId}/user`,
      request
    );
  }
  public deleteUserEventSession(eventId: number, sessionId: number) {
    return this.delete(`event/${eventId}/session/${sessionId}/user`);
  }

  public getUserEventRegistration(): Promise<UserEventRegistrationModel> {
    return this.get<UserEventRegistrationModel>(`usereventregistration`);
  }
  public addUserEventRegistration(request: AddUserEventRegistrationRequest) {
    return this.post(`usereventregistration`, request);
  }
  public updateUserEventRegistration(
    request: UpdateUserEventRegistrationRequest
  ) {
    return this.put(`usereventregistration`, request);
  }
  public getUserEventSession(eventId: string): Promise<any> {
    return this.get(`event/user/session/${eventId}`);
  }
  public getLearningpathEvent(Id: string): Promise<any> {
    return this.get(`Event/learningpath/${Id}`);
  }
  public isUserWhitelisted(email: string): Promise<any> {
    return this.get<any>(`user/${email}/userwhitelist`);
  }
  public searchAssets(searchKey: string): Promise<TechReadinessAssetModel[]> {
    return this.get<TechReadinessAssetModel[]>(
      `assets/search/${this.encodedSearchText(searchKey)}`
    );
  }

  public searchAssetBlobContent(
    searchKey: string
  ): Promise<TechReadinessAssetModel[]> {
    return this.get<TechReadinessAssetModel[]>(
      `assets/blobcontent/search?searchText=${this.encodedSearchText(
        searchKey
      )}`
    );
  }

  public searchSuggestions(
    searchKey: string
  ): Promise<TechReadinessAssetModel[]> {
    return this.get<TechReadinessAssetModel[]>(
      `assets/suggestions?searchText=${this.encodedSearchText(searchKey)}`
    );
  }

  public getAllSearchFilters(): Promise<TechHubSearchFilterModel[]> {
    return this.get<TechHubSearchFilterModel[]>(`assets/search/filters`);
  }
  private encodedSearchText(searchKey: string): string {
    return encodeURIComponent(searchKey.replace(/[#?]/g, ""));
  }

  public getCommunication(communicationId: string): Promise<any> {
    return this.get<any>(`communication/${communicationId}`);
  }

  public downloadCalender(communicationId: string): Promise<unknown> {
    return this.get<unknown>(`communication/calander/${communicationId}`);
  }

  public getRegisteredEvents(): Promise<any> {
    return this.get<any>(`Event/user/sessions`);
  }

  public updateNotifications(): Promise<any> {
    return this.post(`Event/user/notifications`, null);
  }
}