import { Component, Input, Renderer2, ElementRef, ViewChild, HostListener } from "@angular/core";

@Component({
  selector: "app-share-popover",
  templateUrl: "./share-popover.component.html",
  styleUrls: ["./share-popover.component.scss"],
})
export class SharePopoverComponent {
  @Input() popoverId: string;
  @Input() popoverType: string;
  @Input() linkedInTitle: string;
  @Input() linkedInUrl: string;
  @Input() emailSubject: string;
  @Input() emailBody: string;
  @Input() yammerMessage: string;

  toggleSharePopover(): void {
    const popoverElement = document.querySelector("#" + this.popoverId);
    const popoverActiveClass = "active";
    const isPopoverActive = popoverElement.classList.contains(
      popoverActiveClass
    );

    const allPopoverElements = document.querySelectorAll(".share-popover");
    [].forEach.call(allPopoverElements, function (el) {
      el.classList.remove(popoverActiveClass);
    });

    if (!isPopoverActive) popoverElement.classList.add(popoverActiveClass);
    }
    closeOnOutsideClick() {
      const popoverActiveClass = "active";
         const allPopoverElements = document.querySelectorAll(".share-popover");
         [].forEach.call(allPopoverElements, function (el) {
           el.classList.remove(popoverActiveClass);
         });
      
  }
  focusToShare() {
          document.getElementById(this.popoverId + "-btn").focus();

  }

  shareLinkedIn() {
    const encodedTitle = encodeURIComponent(this.linkedInTitle);
    const encodedUrl = encodeURIComponent(this.linkedInUrl);
    const windowUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedTitle}&summary=`;
      window.open(windowUrl);
      this.closeOnOutsideClick();
  }

  shareYammer() {
    const encodedStatus = encodeURIComponent(this.yammerMessage);
    const windowUrl = `https://www.yammer.com/messages/new?login=true&trk_event=yammer_share&status=${encodedStatus}&group_id=null`;
      window.open(windowUrl);
            this.closeOnOutsideClick();

  }

  shareEmail() {
    const encodedSubject = encodeURIComponent(this.emailSubject);
    const encodedBody = encodeURIComponent(this.emailBody);
    const windowUrl = `mailto:?subject=${encodedSubject}&body=${encodedBody}`;
      window.location.href = windowUrl;
            this.closeOnOutsideClick();
  }
}
