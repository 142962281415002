import { SasUrlFormatPipe } from './pipe/sas-url-format.pipe';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ListPaginationComponent } from "./components/list-pagination/list-pagination.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SearchComponent } from "./components/search/search.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CalloutComponent } from "src/app/shared/components/callout/callout.component";
import { TrackEventDirective } from "./directives/blob-link.directive";
import { TabTrackEventDirective } from "./directives/tab-track.directive";
import { TrackPublicBlobEventDirective } from "./directives/public-blob-link.directive";
import { HomeSearchComponent } from "./components/home-search/home-search.component";
import { NgInitDirective } from "./directives/ng-init.directive";
import { FallbackImageDirective } from "./directives/fallback-image.directive";
import {MinutesToHoursPipe} from "./pipe/minutes-to-hours.pipe";
import {MatchesRolePipe} from "./pipe/matches-role.pipe";
import {YammerDateFormatPipe} from "./pipe/yammer-date-format.pipe"
import { SearchV2Component } from './components/searchv2/searchv2.component';
import { SharePopoverComponent } from './components/share-popover/share-popover.component';
import { SpotlightCardComponent } from './components/spotlight-card/spotlight-card.component';
import { DashboardButton } from './components/dashboard-button/dashboad-button.component';
import { SignInModal } from './components/sign-in-modal/sign-in-modal.component';
import { FirstRunModal } from './components/firstrun-modal/firstrun-modal.component';
import { LearningPathLegend } from './components/learning-path-legend/learning-path-legend.component';
import { HtmlVideoPlayerComponent } from './components/html-video-player/html-video-player.component';
import { VideoHolderComponent } from './components/video-holder/video-holder.component';
import {ContactSupportModal} from './components/contact-support-modal/contact-support-modal.component'
import { HelpSupportModal } from './components/help-support-modal/help-support-modal.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    ListPaginationComponent,
    SearchComponent,
    CalloutComponent,
    HomeSearchComponent,
    TrackEventDirective,
    TabTrackEventDirective,
    TrackPublicBlobEventDirective,
    NgInitDirective,
    FallbackImageDirective,
    MinutesToHoursPipe,
    MatchesRolePipe,
    YammerDateFormatPipe,
    SafeHtmlPipe,
    SearchV2Component,
    SharePopoverComponent,
    SasUrlFormatPipe,
    HtmlVideoPlayerComponent,
    VideoHolderComponent,
    SpotlightCardComponent,
    DashboardButton,
    LearningPathLegend,
    SignInModal,
    FirstRunModal,
    ContactSupportModal,
    HelpSupportModal
  ],
  declarations: [
    ListPaginationComponent,
    SearchComponent,
    CalloutComponent,
    HomeSearchComponent,
    TrackEventDirective,
    TabTrackEventDirective,
    TrackPublicBlobEventDirective,
    NgInitDirective,
    FallbackImageDirective,
    MinutesToHoursPipe,
    MatchesRolePipe,
    YammerDateFormatPipe,
    SafeHtmlPipe,
    SearchV2Component,
    SharePopoverComponent,
    SasUrlFormatPipe,
    HtmlVideoPlayerComponent,
    VideoHolderComponent,
    SpotlightCardComponent,
    DashboardButton,
    LearningPathLegend,
    SignInModal,
    FirstRunModal,
    ContactSupportModal,
    HelpSupportModal
  ],
  providers: [SignInModal, FirstRunModal,ContactSupportModal,HelpSupportModal],
})
export class SharedModule {}
