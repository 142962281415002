import { AppConfig } from "src/app/app.config";
import { Injectable } from "@angular/core";

@Injectable()
export class AuthConfig {
  aadClientId: string;
  constructor() {
    this.aadClientId = AppConfig.aadClientId;
  }
}
