import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule, ErrorHandler } from "@angular/core";
import { AppConfig } from "./app.config";
import { AdalService, AdalInterceptor } from "adal-angular4";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { CoreModule } from "./core/core.module";
import { AuthConfig } from "./shared/services/auth-config.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PartnerPortalConfig } from "./shared/services/partner-portal.config.service";
import {
  ProgressRingService,
  AppConfigBase,
  DashboardService,
  ProgressRingModule,
} from "mwt-components";
import { LandingComponent } from "./landing/landing.component";
import { GlobalErrorHandler } from "./shared/services/global-error-handler.service";
import { UrlSerializer } from "@angular/router";
import { LowerCaseUrlSerializer } from "./lowerCaseUrlSerializer";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { UniversalHeaderModule } from "mwt-components";
import { CustomAdalInterceptor } from "./shared/services/custom-adal-interceptor";
import { SignInModal } from "./shared/components/sign-in-modal/sign-in-modal.component";
import { FirstRunModal } from "./shared/components/firstrun-modal/firstrun-modal.component";
import { SharedModule } from "./shared/shared.module";
import { ContactSupportModal } from "./shared/components/contact-support-modal/contact-support-modal.component";

export function adalInit(adalService: AdalService, appConfig: AppConfig): any {
  return () =>
    appConfig.load().then((config) => {
      const aadlConf = {
        tenant: "common",
        clientId: config.aadClientId,
        resource: config.appIdUri,
        cacheLocation: "localStorage",
        endpoints: {
          //Graph API
          //'https://graph.microsoft.com': config.aadClientId,
          //Landing API
          //"https://api.yammer.com": config.aadClientId,
          "https://localhost:44341": config.aadClientId,
          "https://landingapi-qa.azurewebsites.net": config.aadClientId,
          "https://landingapi-dev.azurewebsites.net": config.aadClientId,
          "https://landingapi-uat.azurewebsites.net": config.aadClientId,
          "https://landingapi-prod.azurewebsites.net": config.aadClientId,
          // Partner API
          "https://partnercenterapi-dev.azurewebsites.net": config.appIdUri,
          "https://partnercenterapi-prod.azurewebsites.net": config.appIdUri,
          //BAP API
          "https://localhost:44301": config.aadClientId,
          "https://mtrh-api-dev.azurewebsites.net/api": config.aadClientId,
          "https://mtrh-api-qa.azurewebsites.net/api": config.aadClientId,
          "https://mtrh-api-uat.azurewebsites.net/api": config.aadClientId,
          "https://mtrh-api-prod.azurewebsites.net/api": config.aadClientId,
        },
        //  extraQueryParameter: 'prompt=consent',
        anonymousEndpoints: [],
      };
      adalService.init(aadlConf);
    });
}

@NgModule({
  declarations: [AppComponent, LandingComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    ProgressRingModule,
    NgbModule,
    BrowserAnimationsModule,
    NgxDocViewerModule,
    UniversalHeaderModule,
    SharedModule
  ],
  providers: [
    ProgressRingService,
    AppConfig,
    AdalService,
    HttpClientModule,
    GlobalErrorHandler,
    {
      provide: APP_INITIALIZER,
      useFactory: adalInit,
      deps: [AdalService, AppConfig],
      multi: true,
    },
    {
      provide: AppConfigBase,
      useClass: PartnerPortalConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomAdalInterceptor,
      multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer,
    },
    AuthConfig,
    DashboardService,
    SignInModal,
    FirstRunModal,
    ContactSupportModal
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
