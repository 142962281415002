/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ErrorHandler, Injectable, Injector, NgZone } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { UnauthorizedAccessError } from "../error/unauthorizedAccessError";
import { TokenRenewalError } from "../error/TokenRenewalError";
import { ContactSupportModal } from '../components/contact-support-modal/contact-support-modal.component';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  public error: string;  
  private contactSupportModal: ContactSupportModal;

  constructor(private injector: Injector, private ngZone: NgZone) { }

  handleError(error: any): void {
    const router = this.injector.get(Router);
    this.ngZone.run(() => {
      if (error.rejection instanceof HttpErrorResponse) {
        router.navigate(["/error"], {
          queryParams: { error: error.rejection.status },
          skipLocationChange: true,
        });
      } else if (error.rejection instanceof UnauthorizedAccessError) {    
        router.navigate(["/error"], {
          queryParams: { error: "403" },
          skipLocationChange: true,
        });
      } else if (error.rejection instanceof TokenRenewalError) {
        router.navigate(["/error"], {
          queryParams: { error: "407" },
          skipLocationChange: true,
        });
      }
    });
  }
}
