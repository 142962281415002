import { NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { UniversalFooterComponent } from "./components/universal-footer/universal-footer.component";
import { throwIfAlreadyLoaded } from "../shared/functions";
import { UniversalHeaderModule } from "mwt-components";
import { ClickOutsideModule } from "ng-click-outside";

@NgModule({
  imports: [
    CommonModule, // we use ngFor
    RouterModule,
    UniversalHeaderModule,
    ClickOutsideModule,
  ],
  exports: [RouterModule, UniversalFooterComponent],
  declarations: [UniversalFooterComponent],
  providers: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}
