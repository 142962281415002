import { Injectable } from "@angular/core";
import { HttpClient, HttpBackend } from "@angular/common/http";
import { mergeMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Constants } from "./shared/constants";

const DEFAULT_ENVIRONMENT_VALUE = "$(environment)";

export interface ITransformAppSettings {
  isNewTransformFlagEnabled: boolean;
}

export interface IContainerSasToken {
  [key: string] : string;
}

export interface IAppSettings {
  landingApiUrl: string;
  techReadinessHubBlobUrl: string;
  techReadinessHubWebAppInstrumentationKey: string;
  aadClientId: string;
  appIdUri: string;
  enviorment: string;
  techReadinessHubApiUrl: string;
  yammerGroupId: string;
  partnerCenterApiUrl: string;
  partnerPortalBlobUrl: string;
  partnerPortalUrl: string;
  containerSasToken : IContainerSasToken;
}

export class LocalAppConfig implements IAppSettings {
  landingApiUrl = "";
  techReadinessHubBlobUrl = "https://mtrhstoragedev.blob.core.windows.net/";
  techReadinessHubWebAppInstrumentationKey = "";
  aadClientId = "";
  appIdUri = "";
  enviorment = "";
  techReadinessHubApiUrl = "";
  yammerGroupId = "";
  partnerCenterApiUrl = "";
  partnerPortalBlobUrl = "";
  partnerPortalUrl = "";
  containerSasToken = {};
}

export interface IConfig {
  environment: string;
  appConfigUrl: string;
}

@Injectable()
export class AppConfig {
  private static settings: IAppSettings;
  private http: HttpClient;

  public static get landingApiUrl(): string {
    return AppConfig.settings.landingApiUrl;
  }

  public static get instrumentationKey(): string {
    return AppConfig.settings.techReadinessHubWebAppInstrumentationKey;
  }

  public static get aadClientId(): string {
    return AppConfig.settings.aadClientId;
  }

  public static get appIdUri(): string {
    return AppConfig.settings.appIdUri;
  }

  public static get techReadinessHubImageBlobUrl(): string {
    return AppConfig.settings.techReadinessHubBlobUrl;
  }

  public static get techReadinessHubBlobUrlOrigin(): string {
    return AppConfig.settings.techReadinessHubBlobUrl;
  }

  public static get techReadinessHubBlobUrl(): string {
    return "download?assetname=";
  }
  public static get enviorment(): string {
    return AppConfig.settings.enviorment;
  }

  public static get techReadinessHubPortalApiUrl(): string {
    return AppConfig.settings.techReadinessHubApiUrl;
  }

  public static get partnerCenterApiUrl(): string {
    return AppConfig.settings.partnerCenterApiUrl;
  }

  public static get partnerPortalBlobUrlOrigin(): string {
    return AppConfig.settings.partnerPortalBlobUrl;
  }

  public static get partnerPortalUrl(): string {
    return AppConfig.settings.partnerPortalUrl;
  }

  public static get containerSasToken(): IContainerSasToken {
    return AppConfig.settings.containerSasToken;
  }


  constructor(httpBackend: HttpBackend) {
    this.http = new HttpClient(httpBackend);
    if (!environment.production) {
      AppConfig.settings = new LocalAppConfig();
    }
  }

  public load(): Promise<IAppSettings> {
    const initreferrer = document.referrer;
    if (initreferrer.indexOf(window.location.host) === -1) {
      sessionStorage.setItem(Constants.DocumentReferrer, initreferrer);
    }

    const jsonFile = "config/config.json";
    const getAppSettings = mergeMap((cfg: IConfig) =>
      this.http.get<IAppSettings>(cfg.appConfigUrl)
    );
    return new Promise((resolve) => {
      this.http
        .get<IConfig>(jsonFile)
        .pipe(getAppSettings)
        .subscribe((settings) => {
          AppConfig.settings = settings;
          resolve(settings);
        });
    });
  }
}
