import { Component, ViewEncapsulation, Input } from '@angular/core';
import { Router, RouterState, RouterStateSnapshot } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConfig } from 'src/app/app.config';
import { ContainerEnum } from '../../constants';


@Component({
  selector: 'ngbd-firstrun-modal-content',
  templateUrl: './firstrun-modal.component.html',
  styleUrls: ["./firstrun-modal.component.scss"],
})

export class FirstRunModalContent {
  constructor(public activeModal: NgbActiveModal) {}
}

@Component({
  selector: 'firstrun-modal-link',
  templateUrl: './firstrun-modal-link.component.html',
  styleUrls: ["./firstrun-modal-link.component.scss"],
})

export class FirstRunModal {

  @Input() imageUrl;
  @Input() videoUrl;

  public get techReadinessHubImageUrl(): string {
    return AppConfig.techReadinessHubImageBlobUrl;
  }

  public transform(value: string, ): any {
    const sasToken = AppConfig.containerSasToken[ContainerEnum.Images].split("?")[1]; 
    return `${value}?${sasToken}`;
  }

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
    this.open(null);
  }

  open(tourClick) {
    const firstRun = window.localStorage.getItem('firstrun');

    if (firstRun === null || tourClick ) {
      const modalRef = this.modalService.open(
        FirstRunModalContent, {size: 'xl', windowClass: 'first-run-modal custom-class'}
      );
      modalRef.componentInstance.imageUrl = this.transform(this.techReadinessHubImageUrl + 'images/icons/firstrun.png');
      modalRef.componentInstance.videoUrl = this.transform(this.techReadinessHubImageUrl + 'images/SiteTourVideo.mp4');
      window.localStorage.setItem('firstrun', 'viewed');
    }
  }
}
