import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-html-video-player',
  templateUrl: './html-video-player.component.html',
  styleUrls: ['./html-video-player.component.scss']
})
export class HtmlVideoPlayerComponent  {

  @Input("videoUrl") videoUrl: string;
  @Input("vttUrl") vttUrl: string;
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  videoStarted: boolean;

  constructor(){
    this.videoStarted = false;
  }

  play(): void {
    this.videoStarted = true;
    this.videoplayer.nativeElement.play();
  }

  showVtt(): boolean{
    return this.vttUrl && this.vttUrl !== "";
  }
}
