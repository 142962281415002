import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { AdalService } from "adal-angular4";

@Injectable({
  providedIn: "root",
})
export class GraphApiService {
  private readonly graphUrl = "https://graph.microsoft.com/v1.0/";

  constructor(private http: HttpClient, private adalService: AdalService) {}

  public async getUserIcon(): Promise<any> {
    const token = await this.adalService
      .acquireToken("https://graph.microsoft.com")
      .toPromise();

    const headersParam = new HttpHeaders({
      Authorization: "Bearer " + token.toString(),
    });

    const userImage = await this.http
      .get(`${this.graphUrl}/me/photos/48x48/$value`, {
        headers: headersParam,
        responseType: "blob",
      })
      .pipe(catchError((response) => throwError(response)))
      .toPromise();

    if (userImage) {
      return await this.createImageFromBlob(userImage);
    }
  }

  private createImageFromBlob(image: Blob): Promise<any> {
    const imagePromise = new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject(null);
      };
      if (image) {
        reader.readAsDataURL(image);
      }
    });
    return imagePromise;
  }
}
