import { Component } from "@angular/core";
import { AuthenticationService } from "../shared/services/authentication.service";
import { AppInsightsService } from "../shared/services/app.insights.service";
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { AppNavigationService } from "../shared/services/app-navigation.service";
import { AdalService } from "adal-angular4";
import { Router } from "@angular/router";
import { AppConfig } from "../app.config";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
})
export class LandingComponent extends BaseComponent {
  constructor(
    appInsightsService: AppInsightsService,
    private authenticationService: AuthenticationService,
    private appNavigationService: AppNavigationService,
    private adalService: AdalService,
    private router: Router,
  ) {super(appInsightsService, 'login-page');}

  ngOnInit(): void {
    if (this.adalService.userInfo.authenticated) {
      this.router.navigate(["/home"]);
    }
    this.appNavigationService.saveReturnUrlToSession();
  }

  signIn(): void {
    this.authenticationService.signIn();
  }
  public get techReadinessHubAssetUrl(): string {
    return AppConfig.techReadinessHubBlobUrl;
  }
  public get techReadinessHubImageUrl(): string {
    return AppConfig.techReadinessHubImageBlobUrl;
  }  
}
