import { Component, ViewEncapsulation } from '@angular/core';
import { Router, RouterState, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from "src/app/shared/services/authentication.service";
import { RedirectLocation } from '../../constants';
import { AppInsightsService } from "src/app/shared/services/app.insights.service";

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
            <h4 class="modal-title">Sign In</h4>
            <p>Learn new skills and discover new topics on Microsoft 365 and Security with step-by-step guidance. Start your journey today by exploring our learning paths and modules.</p>
    </div>
    <div class="modal-body">
    <div class="row">
          <div class="col-lg-6 col-sm-12 list-column">
              <ul>
                  <li><i class="mdl2-completed"></i>Explore content by solution area</li>
                  <li><i class="mdl2-completed"></i>Discover learning paths</li>
                  <li><i class="mdl2-completed"></i>Find a module to learn a skill</li>
                  <li><i class="mdl2-completed"></i>Filter by role or proficiency to locate learning content quickly</li>
                  <li><i class="mdl2-completed"></i>Create and track your custom learning journey</li>
              </ul>
          </div>
          <div class="col-lg-6 col-sm-12 sign-in-column">
            <span>
                <p>Sign in using your company or organization ID to create a personalized learning journey that will help you reach your objectives and grow your business.</p>
                <button type="button" class="sign-in-btn btn btn-primary" (click)="signIn()">SIGN IN</button>
            </span>
          </div>
      </div>
    </div>
  `,
  styleUrls: ["./sign-in-modal.component.scss"],
})
export class NgbdModalContent {
  snapshot: RouterStateSnapshot;
  constructor(public activeModal: NgbActiveModal, 
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private appInsightsService: AppInsightsService
) {}
  signIn() {
    this.appInsightsService.trackEvent("ExternalLink", "SignIn");
    this.activeModal.close('Close click'); 
    this.authenticationService.signIn();
}

}

@Component({
  selector: 'sign-in-modal',
  templateUrl: './sign-in-modal.component.html'
})
export class SignInModal {
  constructor(private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute
    ) {}

  open(redirectLocation?: RedirectLocation) {
    switch (redirectLocation) {
      case "dashboard":
        this.router.navigate([], {relativeTo: this.route, queryParams: { returnUrl: "/dashboard/my-learning-path" } });
        break;
      case "home":
        this.router.navigate([], {relativeTo: this.route, queryParams: { returnUrl: "/" } });
        break;
      case "current":
      default:
        this.router.navigate([], {relativeTo: this.route, queryParams: { returnUrl: window.location.href.replace(window.location.origin, "") } });
      }

    const modalRef = this.modalService.open(NgbdModalContent, {size: 'xl', windowClass: 'custom-class', centered: true});
  }
}
