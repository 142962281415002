import { Component } from '@angular/core';
import { Router,  RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from "src/app/shared/services/authentication.service";
import * as _ from "lodash";


@Component({
  selector: 'ngbd-modal-content',
  templateUrl: './contact-support-modal.component.html',
  styleUrls: ["./contact-support-modal.component.scss"],
})
export class NgbdModalContent {
  snapshot: RouterStateSnapshot;
  constructor(public activeModal: NgbActiveModal,     
    private router: Router,   
    private route: ActivatedRoute,
) {}
AllowGuestUser(){  
  window.sessionStorage.setItem("isGuestUser",'true'); 
  return this.redirectToVideoPage();
}

private async redirectToVideoPage() {
  if(!_.isNil(window.sessionStorage.getItem("redirectingUrl"))){
      const redirectingUrl =window.sessionStorage.getItem("redirectingUrl");
      if(redirectingUrl.includes('video-training-page')){
        window.sessionStorage.removeItem("redirectingUrl"); 
        this.router.navigateByUrl(redirectingUrl);  
        this.activeModal.close('success');
      }else{ 
        this.router.navigateByUrl(""); 
      }
      this.activeModal.close('success');
    }else{      
      this.router.navigateByUrl(""); 
      this.activeModal.close('success');
    }  
}

}

@Component({
  selector: 'contact-support-modal',
  templateUrl: './contact-support-modal.component.html'
})
export class ContactSupportModal {
  constructor(private modalService: NgbModal
    ) {}

  open() {
    const modalRef = this.modalService.open(NgbdModalContent, {size: 'xl', windowClass: 'custom-class', centered: true});
  }
}








