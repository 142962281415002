import { Injectable, Output, EventEmitter } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UhfMenuService {
  private UHFHeaderApiUrl = environment.uhfHeaderApiUrl;
  private UHFFooterApiUrl = environment.uhfFooterApiUrl;
  @Output() public notificationTrigger: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient) {}

  getHeader(): Observable<any> {
    return this.http
      .get(this.UHFHeaderApiUrl, { responseType: "text" })
      .pipe(map((data) => data));
  }

  getFooter(): Observable<any> {
    return this.http
      .get(this.UHFFooterApiUrl, { responseType: "text" })
      .pipe(map((data) => data));
  }
}
