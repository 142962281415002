import { Component } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: "dashboard-button",
  templateUrl: "./dashboard-button.component.html",
  styleUrls: ["./dashboard-button.component.scss"],
})
export class DashboardButton {
  public get techReadinessHubImageUrl(): string {
    return AppConfig.techReadinessHubImageBlobUrl;
  }

  constructor(
    private router: Router
  ){}

  onVisitDashboard($event): void {
    this.router.navigate(['/dashboard/my-learning-path'])
  }

 }