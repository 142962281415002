import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'minutestohours'
})
export class MinutesToHoursPipe implements PipeTransform {
  transform(value: number): string {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor(value % 3600 / 60);
    if (hours <= 0) {
      if (minutes == 1) {
        return minutes + ' minute ';
      }
      else {
        return minutes > 0 ? minutes + ' minutes ' : 0 + ' hours';
      }

    }
    else if (hours > 1) {
      if (minutes == 1) {
        return hours + ' hours ' + minutes + ' minute';
      }
      else {
        return minutes > 0 ? hours + ' hours ' + minutes + ' minutes ' : hours + ' hours';
      }
    }
    else if (hours == 1) {
      if (minutes == 1) {
        return hours + ' hour ' + minutes + ' minute';
      }
      return minutes > 0 ? hours + ' hour ' + minutes + ' minutes ' : hours + ' hour';
    }
  }
}