import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AdalService } from 'adal-angular4';
import { AuthenticationService } from './authentication.service';
import * as _ from "lodash";

@Injectable()
export class CustomAdalInterceptor implements HttpInterceptor {
 
    constructor(private adal: AdalService, private authenticationService : AuthenticationService) { }
 
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
 
        // if the endpoint is not registered 
        // or if the header 'skip-adal' is set
        // then pass the request as it is to the next handler
        const resource = this.adal.getResourceForEndpoint(request.url);
        const skipAdal = request.headers.get('skip-adal');
 
        // in adition to the above two conditions, if the user is not autheticated continue the request without token.
        if (!resource || skipAdal || !this.adal.userInfo.authenticated) {
            console.log("IsUserAuthenticated" + this.adal.userInfo.authenticated);
            return next.handle(request).pipe(
                catchError(err => {
                    console.log(err);
                    if(err.status == 401){
                        this.authenticationService.signIn();
                        return Observable.throw(err);
                    }
                    return Observable.throw(err);
                })
            );
        }
 
        // if the endpoint is registered then acquire and inject token
        return this.adal.acquireToken(resource)
            .pipe(
                mergeMap((token: string) => {
                    // clone the request and replace the original headers with
                    // cloned headers, updated with the authorization
                    if(_.isNil(token))
                    {
                        console.log('EmptyToken');
                        this.authenticationService.signIn();
                    }

                    const authorizedRequest = request.clone({
                        headers: request.headers.set('Authorization', 'Bearer ' + token),
                    });
 
                    return next.handle(authorizedRequest).pipe(
                        catchError(err => {
                            console.log(err);
                            if(err.status == 401){
                                this.authenticationService.signIn();
                                return Observable.throw(err);
                            }
                        })
                    );
                }
            )
        )
    }
}