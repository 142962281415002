import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Router } from "@angular/router";
import { AdalService } from "adal-angular4";
import { UserService } from "./user.service";
import { ProgressRingService } from "mwt-components";
import { AuthGuardService } from "src/app/shared/services/auth-gaurd.service";
import { SignInModal } from "../components/sign-in-modal/sign-in-modal.component";

@Injectable()
export class RoleAuthGuardService extends AuthGuardService {
  constructor(
    adalService: AdalService,
    userService: UserService,
    progressRingService: ProgressRingService,
    router: Router,
    signInModal: SignInModal,
  ) {
    super(adalService, userService, progressRingService, router, signInModal);
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    let allowRoute = false;
    const res: any = await this.invokeDefaultCanActivate(route, state);
    if (res) {
      const permissions = route.data.permissions as Array<string>;
      allowRoute = this.checkPermissionExist(permissions) ||  this.isWhiteListedUser();
      if (!allowRoute) {
        this.router.navigate(["/error"], {
          queryParams: { error: "403" },
          skipLocationChange: true,
        });
      }
    } else {
      allowRoute = false;
    }
    return allowRoute;
  }

  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | boolean {
    return this.canActivate(childRoute, state);
  }
  async invokeDefaultCanActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return super.canActivate(route, state);
  }
  checkPermissionExist(permissions: Array<string>): boolean {
    const hasPermission = this.userService.checkPermissionExist(permissions);
    return hasPermission;
  }

  isWhiteListedUser(): boolean{
    return this.userService.isWhitelistedUser;
  }
}
