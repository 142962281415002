export class PartnerDetailsModel {
  mpnId: string;
  partnerType: PartnerTypeEnum;
}

export enum PartnerTypeEnum {
  None,
  Partner,
  NonPartner,
}
