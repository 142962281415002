import { Component, Input, Renderer2, ElementRef, ViewChild, HostListener } from "@angular/core";
import { RedirectLocation } from "../../constants";
import { SignInModal } from "../sign-in-modal/sign-in-modal.component";
import { AdalService } from "adal-angular4";

@Component({
  selector: "app-spotlight-card",
  templateUrl: "./spotlight-card.component.html",
  styleUrls: ["./spotlight-card.component.scss"],
})
export class SpotlightCardComponent {
  @Input() spotlightId: string;
  @Input() backgroundColor: string;
  @Input() iconName: string;
  @Input() title: string;
  @Input() description: string;
  @Input() linkTitle: string;
  @Input() linkHref: string;
  @Input() containerHeight: string;
  @Input() requiresAuth: boolean;
  
  redirectLocations = Object.assign({},RedirectLocation);
  userAuthed = false;

  constructor (
    private signInModal: SignInModal,
    private adal: AdalService
  ) {  }

    ngOnInit() {
      this.userAuthed = this.adal.userInfo.authenticated;
    }

  open(redirectLocation?: RedirectLocation) {
    this.signInModal.open(redirectLocation);
  }
}