import { RoleModel } from "./role.model";

export class UserModel {
  id: string;
  firstName: string;
  lastName: string;
  aadProfile: string;
  mpnId: string;
  isPartner: boolean;
  email: string;
  roleId: string;
  businessSegmentId: string;
  userIcon: any;
  fullName: string;
  roleName: string;
  lastAccessed: Date;
  role: RoleModel;
  initials: string;
  systemRoles: any[];
  canSetPartnerDetails: boolean; //TODO - Temp fix for partner issue in other tools
  public company: string;
  public countryId: string;
}
