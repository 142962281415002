import { ElementRef, HostListener, Directive, Input } from '@angular/core';

@Directive({
  selector: '[appFallbackImage]'
})
export class FallbackImageDirective {
  @Input() appFallbackImage: string;

  constructor(private elementRef: ElementRef) {    
   }
  
  @HostListener("error") onError(): void {
    const element: HTMLImageElement = this.elementRef.nativeElement as HTMLImageElement;
    element.src = this.appFallbackImage || "https://via.placeholder.com/150";
  }
}
