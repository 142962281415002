import { AppConfigBase, IUser } from "mwt-components";
import { AppConfig } from "src/app/app.config";
import { Injectable } from "@angular/core";
import { UserService } from "./user.service";

@Injectable()
export class PartnerPortalConfig implements AppConfigBase {
  instrumentationKey: string;
  enviorment: string;
  adminApiUrl: string;

  toolId: string;
  landingApiUrl: string;
  constructor(private userService: UserService) {
    this.landingApiUrl = AppConfig.landingApiUrl;
    this.toolId = null;
    this.enviorment = AppConfig.enviorment;
    this.instrumentationKey = AppConfig.instrumentationKey;
  }
  basicAuthPrimaryKey: string;
  appApiUrl: string;
  alternateToolId: string;

  public get userInformation(): IUser {
    return this.userService.userInformation;
  }
}
