/* eslint-disable @typescript-eslint/no-unused-vars */
import { Directive, HostListener, ElementRef, Input } from "@angular/core";
import { AppInsightsService } from "src/app/shared/services/app.insights.service";

@Directive({
  selector: "[appTabTrackEvent]",
})
export class TabTrackEventDirective {
  @Input() tabName: string;
  public linkUrlvalue: any;

  constructor(
    private _elemRef: ElementRef,
    private appInsightsService: AppInsightsService
  ) {}

  @HostListener("click") onClick(event: Event): void {
    try {
      this.linkUrlvalue = {
        currentPageUrl: window.location.href,
        ActionType: "TabClick",
      };
      this.appInsightsService.trackEvent(this.tabName, this.linkUrlvalue);
    } catch (ex) {
      console.log(ex);
    }
  }
}
