import { Pipe, PipeTransform } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { ContainerEnum } from '../constants';

@Pipe({
  name: 'sasUrlFormat'
})
export class SasUrlFormatPipe implements PipeTransform {

  transform(value: string, ): any {
    try {
      const containerName = value.split('/')[3];
      const sasToken = AppConfig.containerSasToken[containerName].split("?")[1];
      return `${value}?${sasToken}`;
    }
    catch (e) {
      return value;
    }
  }

}
