import { Injectable } from "@angular/core";
import { OnRampApiBaseService } from '../core/services/base/on-ramp-api-base.service';
import { BusinessSegmentRoleModel } from '../shared/models/business-segment-role.model';
import { CountryModel } from "../shared/models/country.model";
import { UserModel } from "../shared/models/user.model";

@Injectable({
  providedIn: "root",
})
export class ProfileApiService extends OnRampApiBaseService {
  public getBusinessSegments(): Promise<BusinessSegmentRoleModel[]> {
    return this.get<BusinessSegmentRoleModel[]>(`BusinessSegments/segment_roles`);
  }

  public getCountryList(): Promise<CountryModel[]> {
    return this.get<CountryModel[]>(`Geographies`);
  }

  public addUser(userDetails: UserModel): Promise<string> {
    return this.post<UserModel, string>(`Users/user`, userDetails);
  }

  public updateUser(userId: string, userDetails: UserModel): Promise<string> {
    return this.put<UserModel, string>(`Users/${userId}`, userDetails);
  }
}
