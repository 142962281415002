import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-video-holder',
  templateUrl: './video-holder.component.html',
  styleUrls: ['./video-holder.component.scss']
})
export class VideoHolderComponent  {

  @Input("videoUrl") videoUrl: string;
  @Input("vttUrl") vttUrl: string;
  @Input("imageUrl") imageUrl: string;
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  videoStarted: boolean;

  constructor(){  
    this.videoStarted = false;  
  }
 

  playVideo(): void {
    this.videoStarted = true;
    this.videoplayer.nativeElement.play();
  }

}
