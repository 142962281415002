import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "./shared/services/user.service";
import { ProgressRingService } from "mwt-components";
import * as _ from "lodash";
import { AdalService } from "adal-angular4";
import { Location } from "@angular/common";
import { ContactSupportModal } from "./shared/components/contact-support-modal/contact-support-modal.component";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "techreadinesshub-app";
  private loadingData = true;

  constructor(
    private adal: AdalService,
    private router: Router,
    private userService: UserService,
    private location: Location,
    private progressRingService: ProgressRingService,
    private contactSupportModal: ContactSupportModal,
  ) {
  }

  ngOnInit(): void {
    if (_.includes(window.location.href, "AADSTS50058")) {
      this.adal.handleWindowCallback();
      this.loadingData = false;
      this.router.navigate(["/error"], {
        queryParams: { error: "407" },
        skipLocationChange: true,
      });
      return;
    }
    if (_.includes(window.location.href, "#error=access_denied")) {
      this.adal.handleWindowCallback();
      this.loadingData = false;
      this.router.navigate(["/error"], {
        queryParams: { error: "403" },
        skipLocationChange: true,
      });
      return;
    }
    this.adal.handleWindowCallback();
    if (this.adal.userInfo.authenticated) {
      this.loadingData = true;
      const path = this.location.path();
      const urlFragments = window.location.href.split("#id_token");
      if (urlFragments.length > 1) {
        this.location.go(path);
      }

      this.init();
    } else if (_.isNil(this.adal.userInfo) || !this.adal.userInfo.loginCached) {
      this.loadingData = false;
    }
  }

  private async init() {
    this.progressRingService.isBusy = true;
    try {
      await this.userService.loadUserInfo();
    } catch (ex) {
      this.loadingData = false;
      this.progressRingService.isBusy = false;  
      throw ex;
    }

    this.loadingData = false;

    this.progressRingService.isBusy = false;
  }

  public get isBusy(): boolean {
    return this.progressRingService.isBusy;
  }

  public get pageLoaded(): boolean {
    return !this.loadingData;
  }
}
