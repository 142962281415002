
import { TechReadinessHubApi } from './../../api/techReadinessHubapi';
import { ProfileApiService } from './../../services/profile-api.service';
import { PartnerCenterApiService } from './../../services/partner-api.service';
import { UserApiService } from './../../services/user-api.service';
import { GraphApiService } from './../../services/graph-api.service';
import { Injectable } from "@angular/core";

import * as _ from "lodash";
import { AdalService } from "adal-angular4";
import { SystemPermissionConstant } from "src/app/shared/constants";
import { BreadcrumbItem } from "mwt-components";
import { ActivatedRoute, Router } from "@angular/router";
import { UserModel } from '../models/user.model';
import { UnauthorizedAccessError } from '../error/unauthorizedAccessError';
import { TokenRenewalError } from '../error/TokenRenewalError';
import { PartnerTypeEnum } from '../models/partner-details.model';
import { siteRole } from '../../shared/constants';

@Injectable({
  providedIn: "root",
})
export class UserService {
  private userInfo: UserModel;
  private loadUserPromise: Promise<boolean>;
  public isWhitelistedUser = false;
  constructor(
    private graphApiService: GraphApiService,
    private userApiService: UserApiService,
    private partnerCenterApiService: PartnerCenterApiService,
    private profileApiService: ProfileApiService,
    private adalService: AdalService,
    private techReadinessportalService: TechReadinessHubApi,
    protected router: Router,
    protected route: ActivatedRoute,    
  ) {
    this.userInfo = new UserModel();
  }

  public get userInformation(): UserModel {
    return this.userInfo;
  }

  public get isPartner(): boolean {
    return this.userInfo.isPartner;
  }

  public isMicrosoftUser(email: string): boolean {
    let isMSUser = false;
    const domain = email.replace(/.*@/, "");
    isMSUser = domain.toLocaleLowerCase() === "microsoft.com";
    return isMSUser;
  }
  public userRole(): string {
    if (!_.isEmpty(this.userInfo.firstName)) {
      if (this.userInfo.isPartner) {
        return siteRole.Partner;
      }
      else if (this.isMicrosoftUser(this.userInfo.email)) {
        return siteRole.Microsoft;
      }
      else {
        return siteRole.Other;
      }
    }
    else {
      return siteRole.Anonymous;
    }
  }
  public hastechReadinessHubAccess(): boolean {
    const hasAccess = this.isPartner || this.isMicrosoftUser(this.userInfo.email);
    return hasAccess;
  }


  public get homeRoute(): string {
    return "/tech-readiness-hub";
  }

  public get techReadinessHubBreadCrumbItem(): BreadcrumbItem {
    return {
      displayText: "Home",
      navigationUrl: this.homeRoute,
    };
  }

  public get firstRunExperiance(): boolean {
    return _.isNil(this.userInfo) || _.isNil(this.userInfo.id) || _.isNil(this.userInfo.countryId) || _.isNil(this.userInfo.company);
  }

  public isAdminUser(): boolean {
    let isAdmin = false;
    // ToDo: Check for Admin Permissions- use onramp specific permission name
    if (this.userInfo.systemRoles) {
      this.userInfo.systemRoles.forEach((role) => {
        if (
          role.permissions.some(
            (p) => p.code == SystemPermissionConstant.TechReadinessAdmin
          )
        ) {
          isAdmin = true;
        }
      });
    }
    return isAdmin;
  }
  public hasPermission(permission: string): boolean {
    let result = false;
    // ToDo: Check for Admin Permissions- use onramp specific permission name
    if (this.userInfo.systemRoles) {
      this.userInfo.systemRoles.forEach((role) => {
        if (
          role.permissions.some(
            (p) => p.code.toLocaleLowerCase() == permission.toLocaleLowerCase()
          )
        ) {
          result = true;
        }
      });
    }
    return result;
  }
  checkPermissionExist(permissions: Array<string>): boolean {
    let hasPermission = false;
    if (this.userInformation.systemRoles) {
      this.userInformation.systemRoles.forEach((role) => {
        if (role.permissions.some((p) => permissions.includes(p.code))) {
          hasPermission = true;
        }
      });
    }
    return hasPermission;
  }
  public async loadUserInfo(): Promise<boolean> {
    if (_.isNil(this.loadUserPromise)) {
      this.loadUserPromise = new Promise(async (resolve, reject) => {
        try {
          if (_.isNil(this.userInfo) || _.isNil(this.userInfo.id)) {
            const user = await this.adalService.getUser().toPromise();
            if (!_.isNil(user)) {
              this.userInfo.email = user.userName;
              this.userInfo.firstName = user.profile.given_name;
              this.userInfo.fullName = user.profile.name;
              this.userInfo.lastName = user.profile.family_name;

              this.isWhitelistedUser = await this.techReadinessportalService.isUserWhitelisted(
                this.userInfo.email
              );

              const result = await this.userApiService.getUserByEmail(this.userInfo.email);

              if (!_.isNil(result)) {
                this.userInfo = result;
                this.userInfo.fullName = user.profile.name;
              }

              if (_.isNil(this.userInfo.isPartner)) {
                try {
                  const partnerInfo = await this.partnerCenterApiService.getPartnerDetails();
                  if (partnerInfo.partnerType != PartnerTypeEnum.None) {
                    this.userInfo.mpnId = partnerInfo.mpnId;
                    this.userInfo.isPartner = partnerInfo.partnerType == PartnerTypeEnum.Partner;
                 
                    if (!_.isNil(this.userInfo.id)) {
                      this.userInfo.canSetPartnerDetails = true;
                      this.profileApiService.updateUser(this.userInfo.id, this.userInfo);
                    }

                    try {
                      //Loading user icon from graph API
                      this.userInfo.userIcon = await this.graphApiService.getUserIcon();
                    } catch (ex) {
                      console.log('Failed to load user icon');
                    }
                  }
                } catch (ex) {
                  if (_.includes(ex, "AADSTS50058")) {
                    reject(new TokenRenewalError('Token renewal failed"'));
                  }
                }
              }
              if (this.firstRunExperiance) {
                const returnUrl = window.location.href.replace(window.location.origin, "");
                this.router.navigate(["/firstrun"], { queryParams: { returnUrl: returnUrl } });
              }

              if (this.userInfo.isPartner == true) {
                try {
                  //Loading user icon from graph API
                  this.userInfo.userIcon = await this.graphApiService.getUserIcon();
                } catch (ex) {
                  console.log('Failed to load user icon');
                }
              }
            }
          }
        } catch (ex) {
          console.log('Failed to load user information', ex);
          reject(ex);
        }
        resolve(true);
      });
    }
    return this.loadUserPromise;
  }
}
