import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root'
})
export abstract class PartnerCenterApiBaseService extends ApiBaseService {

  constructor(protected http: HttpClient) {
    super(http, AppConfig.partnerCenterApiUrl);
  }
}
