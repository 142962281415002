import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  RouterStateSnapshot,
} from "@angular/router";
import { Router } from "@angular/router";
import { AdalService } from "adal-angular4";
import { UserService } from "./user.service";
import * as _ from "lodash";
import { ProgressRingService } from "mwt-components";
import { delay } from "q";
import { SignInModal } from "../components/sign-in-modal/sign-in-modal.component";
import { RedirectLocation } from "../constants";

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {

  redirectLocations = Object.assign({},RedirectLocation);

  constructor(
    protected adalService: AdalService,
    protected userService: UserService,
    protected progressRingService: ProgressRingService,
    protected router: Router,
    private signInModal: SignInModal,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | boolean {
    this.adalService.handleWindowCallback();
    return this.checkCanActivate(route, state);
  }

  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | boolean {
    return this.canActivate(childRoute, state);
  }

  private async checkCanActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (
      !_.isNil(this.adalService.userInfo) &&
      this.adalService.userInfo.loginCached &&
      !this.adalService.userInfo.authenticated
    ) {
      await this.delayTokenRefeshCompleted();
    }
    const fastTrackTitle = "FastTrack Ready Partner";
    const isUnAuthFastTrack = (window.localStorage.getItem("SolutionAreaTitle") == fastTrackTitle) ? true : false;    
    const isGuestUserExceptFastTrack=(window.sessionStorage.getItem("isGuestUser")=='true') && !isUnAuthFastTrack;
    if (this.adalService.userInfo.authenticated || isGuestUserExceptFastTrack ) {
      this.progressRingService.isBusy = true;
      try {
        await this.userService.loadUserInfo();
      } catch (ex) {
        // catch error
      }
      this.progressRingService.isBusy = false;
      return true;
    } else {
      this.signInModal.open(this.getRedirectLocation(state));
    }
    return false;
  }

  private async delayTokenRefeshCompleted(): Promise<void> {
    const maxCount = 5;
    let count = 0;
    while (count < maxCount && !this.adalService.userInfo.authenticated) {
      count++;
      await delay(500);
    }
  }

  private getRedirectLocation(state: RouterStateSnapshot): RedirectLocation {
    let location = this.redirectLocations.Current;
    if (state.url.includes("dashboard"))
    {
      location = this.redirectLocations.Dashboard
    }
    return location;
  }
}
