import { Injectable } from "@angular/core";
import { OnRampApiBaseService } from '../core/services/base/on-ramp-api-base.service';
import { UserModel } from "../shared/models/user.model";
import { BehaviorSubject } from 'rxjs';
import { SiteRoleModel } from "../tech-readiness/admin/models/site-role.model";
  
@Injectable({
  providedIn: "root",
})
export class UserApiService extends OnRampApiBaseService {
  private videotime= new BehaviorSubject(null);
 public changeImportData(data) {
    this.videotime.next(data);
  }

  public getImportData() {
    return this.videotime.asObservable();
  }
  public getUserByEmail(email: string): Promise<UserModel> {
    return this.get<UserModel>(`Users/email?emailId=${email}`);
  }

  public isPartner(): Promise<boolean> {
    return this.get<boolean>(`Users/partner`);
  }

  getUserById(userId: string): Promise<UserModel> {
    return this.get<UserModel>(`Users/${userId}`);
  }
  updateUserRole(userRoles: any[], userId: string): Promise<boolean> {
    return this.post<any[], boolean>(
      `Users/updateUserRole?userId=${userId}`,
      userRoles
    );
  }
  getUsersList(): Promise<any> {
    return this.get<UserModel>(`Users`);
  }
  getUsers(page: number, size: number): Promise<any[]> {
    return this.get<any[]>(`Users/users?page=${page}&count=${size}`);
  }
  getUsersListForSearch(searchKey: string): Promise<any[]> {
    return this.get<any[]>(`Users/search?searchKey=${searchKey}`);
  }

  public getRolesByUser(userId: string): Promise<SiteRoleModel[]> {
    return this.get<SiteRoleModel[]>(`Users/rolesByUser?userId=${userId}`);
  }

}
