/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as _ from "lodash";

export function throwIfAlreadyLoaded(parentModule: any, moduleName: string): void {
  if (parentModule) {
    throw new Error(
      `${moduleName} has already been loaded. Import Core modules in the AppModule only.`
    );
  }
}

export function cssLinkLoadAsync(
  htmlString: string,
  parentContainer: HTMLElement
): Promise<void> {
  let linkLoadPromise: Promise<void>;
  const div = document.createElement("div");
  div.innerHTML = htmlString.trim();
  if (div.childNodes) {
    const linkElements = _.filter(div.childNodes, (node) => {
      return node.nodeName === "LINK";
    });
    linkLoadPromise = new Promise<void>((resolve) => {
      let linksCount: number = linkElements.length;
      linkElements.forEach((link: HTMLElement) => {
        const loadComplete = () => {
          linksCount--;
          if (linksCount === 0) {
            resolve();
            link.onload = null;
            link.onerror = null;
          }
        };
        link.onload = loadComplete;
        link.onerror = loadComplete;
      });
    });
  }
  parentContainer.appendChild(div);
  return linkLoadPromise;
}
