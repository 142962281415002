import { Injectable } from "@angular/core";
import { AdalService } from "adal-angular4";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "./user.service";
import { AppNavigationService } from "./app-navigation.service";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  constructor(
    private userService: UserService,
    private adalService: AdalService,
    private router: Router,
    private route: ActivatedRoute,
    private appNavigationService: AppNavigationService
  ) {}

  private async redirectToHome() {
    await this.userService.loadUserInfo();
    this.appNavigationService.deeplinkOrNavigateHome();
  }

  async signIn(): Promise<void> {
    this.adalService.handleWindowCallback();
    if (this.adalService.userInfo.authenticated) {
      await this.redirectToHome();
    } else {
      this.appNavigationService.saveReturnUrlToSession();
      this.adalService.login();
    }
  }
}
