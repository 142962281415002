import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: "yammerDateFormat",
})
export class YammerDateFormatPipe extends DatePipe implements PipeTransform {
  transform(value: Date): any {
    const DATE_FMT = "MM/dd/yyyy";
    return `${super.transform(value, DATE_FMT)} at ${super.transform(value, 'shortTime')}`;
  }
}
