/* eslint-disable @typescript-eslint/no-unused-vars */
import { Directive, HostListener, ElementRef } from "@angular/core";
import { AppInsightsService } from "src/app/shared/services/app.insights.service";
import { AppConfig } from "src/app/app.config";

@Directive({
  selector: "[appTrackEvent]",
})
export class TrackEventDirective {
  public linkUrl: string;
  public linkUrlvalue: any;
  public trackName: string;

  constructor(
    private _elemRef: ElementRef,
    private appInsightsService: AppInsightsService,
  ) {}

  @HostListener("click") onClick(): any {
    try {
      this.linkUrl = this._elemRef.nativeElement.href;
        if (this.linkUrl.includes(window.location.origin)) {
          this.trackName = "Internal Link";
          this.linkUrlvalue = {
            linkUrl: this.linkUrl,
            ActionType: "InternalLinkClick",
          };
          this.appInsightsService.trackEvent("InternalLink", this.linkUrlvalue);
        } else {
          this.trackName = "External Link";
          this.linkUrlvalue = {
            linkUrl: this.linkUrl,
            ActionType: "ExternalLinkClick",
          };
          this.appInsightsService.trackEvent("ExternalLink", this.linkUrlvalue);
        }
      
    } catch (ex) {
      console.log(ex);
    }
  }
}
