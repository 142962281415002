/* eslint-disable @typescript-eslint/no-unused-vars */
import { Directive, HostListener, ElementRef, OnInit } from "@angular/core";
import { AppInsightsService } from "src/app/shared/services/app.insights.service";
@Directive({
  selector: "[appTrackPublicBlobEvent]",
})
export class TrackPublicBlobEventDirective {
  constructor(
    private _elemRef: ElementRef,
    private appInsightsService: AppInsightsService
  ) {}

  @HostListener("click") onClick(event: Event): void {
    try {
      const linkUrl = this._elemRef.nativeElement.href;
      const trackName = "Public Blob Link";
      const linkUrlvalue = {
        linkUrl: linkUrl,
        ActionType: "PublicBlobLink",
      };
      this.appInsightsService.trackEvent("PublicBlobLink", linkUrlvalue);
    } catch (ex) {
      console.log(ex);
    }
  }
}
