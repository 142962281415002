import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';

export abstract class ApiBaseService {

    constructor(protected http: HttpClient, protected baseUrl: string) { }

    protected async get<T>(endpoint: string, responseType = null): Promise<T> {
        if (_.isNil(responseType)) {
            return this.http
                .get<T>(this.getUrl(endpoint))
                .toPromise()
                .catch((err) => {
                    throw err;
                });
        } else {
            return this.http
                .get<T>(this.getUrl(endpoint), { responseType: responseType })
                .toPromise()
                .catch((err) => {
                    throw err;
                });
        }
    }

    protected post<T, K>(endpoint: string, value: T): Promise<K> {
        return this.http
            .post(this.getUrl(endpoint), value)
            .toPromise()
            .catch((err) => {
                throw err;
            }) as Promise<K>;
    }

    protected async put<T, K>(endpoint: string, value: T): Promise<K> {
        return this.http
            .put<K>(this.getUrl(endpoint), value)
            .toPromise()
            .catch((err) => {
                throw err;
            });
    }

    protected async delete<T>(endpoint: string): Promise<T> {
        return this.http
            .delete<T>(this.getUrl(endpoint))
            .toPromise()
            .catch((err) => {
                throw err;
            });
    }

    protected getUrl(path: string): string {
        return `${this.baseUrl}/${path}`;
    }
}
