import { DefaultUrlSerializer, UrlTree } from "@angular/router";
import * as _ from "lodash";
import { Injectable } from "@angular/core";

@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    return _.includes(decodeURIComponent(url), "/download?")
      ? super.parse(url)
      : super.parse(url.toLowerCase());
  }
}
