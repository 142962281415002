/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "./shared/services/auth-gaurd.service";
import { LandingComponent } from "./landing/landing.component";
import { GlobalErrorHandler } from "./shared/services/global-error-handler.service";
import { RoleAuthGuardService } from "./shared/services/role-auth-guard.service";

export const appRoutes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./tech-readiness/tech-readiness-hub.module").then((m) => m.TechReadinessHubModule),
  },
  {
    path: "learning-paths",
    loadChildren: () =>
      import("./tech-readiness/tech-readiness-hub.module").then((m) => m.TechReadinessHubModule),
  },
  {
    path: "login",
    component: LandingComponent,
  },
  {
    path: "base-pages",
    loadChildren: () =>
      import("./_base-pages/base-pages.module").then((m) => m.BasePagesModule),
  },
  {
    path: "firstrun",
    loadChildren: () =>
      import("./firstrun/firstrun.module").then((m) => m.FirstrunModule),
    canActivate: [AuthGuardService],
  },
  {
    path: "error/:error",
    loadChildren: () =>
      import("./error-page/error-page.module").then((m) => m.ErrorPageModule),
  },
  {
    path: "faq",
    loadChildren: () =>
      import("./tech-readiness/help/help.module").then((m) => m.HelpModule),
  },
  {
    path: "**",
    loadChildren: () =>
      import("./error-page/error-page.module").then((m) => m.ErrorPageModule),
  },
  {
    path: "help-support",
    loadChildren: () =>
      import("./tech-readiness/help-support-page/help-support-page.module").then((m) => m.HelpSupportPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        scrollPositionRestoration: "top",
        anchorScrolling: "enabled",
        enableTracing: true,
      },
      //{ enableTracing: true } // <-- debugging purposes only
    ),
  ],
  providers: [AuthGuardService, GlobalErrorHandler, RoleAuthGuardService],
  exports: [RouterModule],
})
export class AppRoutingModule {}
