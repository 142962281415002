/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Component,
  OnInit,
  Renderer2,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { Router } from "@angular/router";
import { AppInsightsService } from "src/app/shared/services/app.insights.service";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  @Output() searchItem = new EventEmitter();
  public showClearSearch: boolean;
  public searchKeyword: any;
  @Input() searchKey: string;
  @Input() redirectPath: string;
  @ViewChild("mainSearch", { static: true }) searchInputText: ElementRef;
  constructor(
    private renderer: Renderer2,
    private router: Router,
    private appInsightsService: AppInsightsService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    if (this.searchInputText !== undefined) {
      this.renderer.removeAttribute(
        this.searchInputText.nativeElement,
        "aria-multiline"
      );
    }
  }

  public search = (text$: Observable<string>): any =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => {
        if (term.length >= 2) {
          return [];
        } else {
          return [];
        }
      })
    );

  redirect(event: any): void {
    if (event.item || this.searchKey) {
      this.searchKeyword = {
        "Search Keyword used": this.searchKey,
      };
      this.appInsightsService.trackEvent("search", this.searchKeyword);
      if (!event.item) {
        event.item = this.searchKey;
      }
      const redirectPath = this.redirectPath ? this.redirectPath : "/search";
      if (
        this.router.url == "/" ||
        this.router.url != `${redirectPath}/${this.searchKey}`
      ) {
        this.router.navigate([redirectPath, this.searchKey]);
      }

      this.searchItem.emit(this.searchKey);
    }
  }

  onFocus(e: Event): void {
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event("input");
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  clearSearch(): void {
    this.showClearSearch = false;
    this.searchKey = null;
  }
}
