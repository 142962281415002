import { AfterViewInit, Injectable } from "@angular/core";
import { AppInsightsService } from 'src/app/shared/services/app.insights.service';

@Injectable()
export abstract class BaseComponent implements AfterViewInit {

  constructor(
    protected appInsightsService: AppInsightsService,
    private pageViewName: string) {}

  ngAfterViewInit(): void {
    const path = window.location.href;
    this.appInsightsService.trackPageView(this.pageViewName, path);
  }
}
