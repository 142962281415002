import { Component } from "@angular/core";
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: "learning-path-legend",
  templateUrl: "./learning-path-legend.component.html",
  styleUrls: ["./learning-path-legend.component.scss"],
})

export class LearningPathLegend {
  public get techReadinessHubImageUrl(): string {
    return AppConfig.techReadinessHubImageBlobUrl;
  }
 }