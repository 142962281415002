import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../app.config";
import * as _ from "lodash";

export abstract class ApiBase {
  constructor(protected http: HttpClient, protected baseUrl: string) {}

  protected get<T>(endpoint: string, responseType = null): Promise<T> {
    if (_.isNil(responseType)) {
      return this.http
        .get<T>(this.getUrl(endpoint))
        .toPromise()
        .catch((err) => {
          throw err;
        });
    } else {
      return this.http
        .get<T>(this.getUrl(endpoint), { responseType: responseType })
        .toPromise()
        .catch((err) => {
          throw err;
        });
    }
  }

  protected post<T, K>(endpoint: string, value: T): Promise<K> {
    return this.http
      .post(this.getUrl(endpoint), value)
      .toPromise()
      .catch((err) => {
        throw err;
      }) as Promise<K>;
  }

  protected put<T, K>(endpoint: string, value: T): Promise<K> {
    return this.http
      .put<K>(this.getUrl(endpoint), value)
      .toPromise()
      .catch((err) => {
        throw err;
      });
  }

  protected delete<T>(endpoint: string): Promise<T> {
    return this.http
      .delete<T>(this.getUrl(endpoint))
      .toPromise()
      .catch((err) => {
        throw err;
      });
  }

  protected getUrl(path: string): string {
    return `${this.baseUrl}/${path}`;
  }
}

//// Derive from this api base class for Landing api
@Injectable({
  providedIn: "root",
})
export abstract class OnRampPageApiBase extends ApiBase {
  constructor(protected http: HttpClient) {
    super(http, AppConfig.landingApiUrl);
  }
}

//// Derive from this api base class for Landing api
@Injectable({
  providedIn: "root",
})
export abstract class GithubApiBase extends ApiBase {
  constructor(protected http: HttpClient) {
    super(http, "https://api.github.com");
  }
}

//// Derive from this api base class for Partner Central api
@Injectable({
  providedIn: 'root'
})
export abstract class techReadinessHubApiBase extends ApiBase {
  constructor(protected http: HttpClient) {
    super(http, AppConfig.techReadinessHubPortalApiUrl);
  }
}