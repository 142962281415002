import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { UhfMenuService } from "../../services/uhf-menu.service";
import { DomSanitizer } from "@angular/platform-browser";
import { ScriptService } from "../../services/script.service";
import { environment } from "src/environments/environment";
import * as jQuery from "jquery";
import { cssLinkLoadAsync } from "src/app/shared/functions";

@Component({
  selector: "app-uhf-footer",
  templateUrl: "./universal-footer.component.html",
  styleUrls: ["./universal-footer.component.scss"],
})
export class UniversalFooterComponent implements OnInit {
  menuData: any;
  restItemsUrl = environment.uhfFooterApiUrl;
  xmlData: any;
  footerHTML: any;
  public footerDisplay = false;
  cssStyles: any;
  javascriptIncludes: any;
  @ViewChild("cssContainer", { static: true }) cssContainer: ElementRef<
    HTMLElement
  >;

  constructor(
    private uhfService: UhfMenuService,
    private domSanitizer: DomSanitizer,
    private script: ScriptService
  ) {}

  ngOnInit(): void {
    (window as any).jQuery = jQuery;
    this.loadFooter();
    this.script.load("uhfMainScript");
  }

  private loadFooter() {
    this.uhfService.getFooter().subscribe(
      (data: any) => {
        this.menuData = data;
        const parser = new DOMParser();
        this.xmlData = parser.parseFromString(this.menuData, "text/xml");
        this.parseXmlData(this.xmlData);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  private async parseXmlData(xmlData: any) {
    const tempHtml = xmlData.getElementsByTagName("footerHtml")[0]
      .childNodes[0];
    const tempCss = xmlData.getElementsByTagName("cssIncludes")[0]
      .childNodes[0];
    const tempJs = xmlData.getElementsByTagName("javascriptIncludes")[0]
      .childNodes[0];

    const cssLoaderTask = cssLinkLoadAsync(
      tempCss.wholeText,
      this.cssContainer.nativeElement
    );
    if (cssLoaderTask) {
      await cssLoaderTask;
    }
    this.javascriptIncludes = this.domSanitizer.bypassSecurityTrustHtml(
      tempJs.wholeText
    );
    this.footerHTML = this.domSanitizer.bypassSecurityTrustHtml(
      tempHtml.wholeText
    );
    this.footerDisplay = this.footerHTML !== null;
  }
}
