import { Injectable } from '@angular/core';
import { PartnerCenterApiBaseService } from '../core/services/base/partner-center-api-base.service';
import { PartnerDetailsModel } from '../shared/models/partner-details.model';

@Injectable({
  providedIn: 'root'
})
export class PartnerCenterApiService extends PartnerCenterApiBaseService {

  public getPartnerDetails(): Promise<PartnerDetailsModel> {
    return this.get<PartnerDetailsModel>(`users/partner-details`);
  }
}
